<template>
  <div class="CustomerData">
    <!-- 导师员工  --- 潜在、全部、成功、所有 -->
    <div style="padding-bottom: 20px">
      <div class="tabs-warp">
        <div
          class="tab-item"
          :class="{ active: item.id === type }"
          v-for="item in tabs"
          :key="item.id"
          @click="handleTabs(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 潜在、全部、成功、所有 -->
      <div style="padding-top: 10px">
        <div
          style="
            font-size: 14px;
            margin: 0 7px;
            background: #fff;
            padding: 0 12px 0 16px;
            box-sizing: border-box;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 43px;
            box-shadow: 0 0 6px #c6c3c3;
            border-radius: 6px;
          "
        >
          <div>
            <span style="color: #343434">
              {{
                type === 4
                  ? "您在当前成功报名："
                  : type === 5
                  ? "您在当前潜在客户："
                  : type === 6
                  ? "您在当前组队总数："
                  : type === 7
                  ? "您在当前买单总数："
                  : ""
              }}
            </span>
            <span style="color: #f68a2c">{{ total }}</span>
          </div>

          <div
            @click="isShowExport = true"
            v-if="userInfo.admin_group_id === 1 && type === 5"
            style="
              color: #fff;
              width: 84px;
              height: 33px;
              line-height: 33px;
              border-radius: 15px;
              background: #4372fc;
              text-align: center;
            "
          >
            导出
          </div>
        </div>
      </div>

      <!-- 潜在、全部、成功、所有 -->
      <div>
        <div
          v-for="(item, index) in customerList"
          :key="index"
          style="padding-top: 16px"
        >
          <div
            style="
              color: #343434;
              font-weight: 400;
              font-size: 12px;
              margin: 0 7px;
              background: #fff;
              padding: 10px 10px 0;
              box-sizing: border-box;
              box-shadow: 0 0 6px #c6c3c3;
              border-radius: 6px;
            "
          >
            <!-- 所有 -->
            <div v-if="type === 6">
              <div
                style="
                  margin-bottom: 9px;
                  background: #e8edfc;
                  border-radius: 4px;
                  border: 1px solid #e8edfc;
                  box-sizing: border-box;
                  padding: 8px;
                  font-weight: blod;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>
                  <div>队长：{{ item.nickname }}</div>
                  <div
                    style="margin-top: 9px"
                    @click="handleMakePhone(item.lists[0].real_telphone)"
                  >
                    电话：{{
                      item.lists.length ? item.lists[0].real_telphone : ""
                    }}
                  </div>
                </div>
                <div>
                  <div
                    @click="handleCopyTeamLink(item, index)"
                    :class="'copy-text' + index"
                    :data-clipboard-text="copyText"
                    style="color: #f68624"
                  >
                    复制该组队链接
                  </div>
                  <div style="margin-top: 9px">
                    队员：{{ item.lists ? item.lists.length : 0 }}人
                  </div>
                </div>
              </div>

              <template v-if="item.lists ? item.lists.length : false">
                <div
                  v-for="(item1, index1) in item.lists"
                  :key="index1"
                  style="padding-bottom: 9px"
                >
                  <div
                    class="_copy-style"
                    style="
                      padding: 8px;
                      box-sizing: border-box;
                      position: relative;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 0 6px;
                        border-bottom: 1px solid #e8e8e8;
                      "
                    >
                      <div style="display: flex; align-items: center">
                        <!-- <div style="width: 21px; height: 21px; border-radius: 50%; background: #00a0e8; margin-right: 7px;"></div> -->
                        <img
                          :src="item1.avatar_url"
                          alt=""
                          style="
                            width: 21px;
                            height: 21px;
                            border-radius: 50%;
                            margin-right: 7px;
                          "
                        />

                        <div style="margin-right: 13px">
                          {{ item1.nickname }}
                        </div>
                        <div>
                          {{ item1.real_telphone ? item.real_telphone : "" }}
                        </div>
                      </div>

                      <div
                        @click="handleMakePhone(item1.real_telphone)"
                        style="color: #00a0e8"
                      >
                        <img
                          src="~@/assets/bohao.png"
                          width="21"
                          height="21"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>

                    <div
                      style="
                        position: absolute;
                        top: 50px;
                        right: 10px;
                        color: #f68624;
                      "
                      @click="handleCreateHb(item1)"
                    >
                      生成海报
                    </div>
                    <div style="margin-top: 9px">
                      报名时间：{{ item1.createtime }}
                    </div>
                    <div style="margin-top: 9px">
                      归属员工：{{
                        item1.from_admin
                          ? item1.from_admin.realname
                          : "未获取到"
                      }}
                    </div>
                    <div style="margin-top: 9px">
                      推荐人：{{ item1.member_id }} {{ item1.nickname }}
                    </div>
                    <div style="margin-top: 9px">
                      学生姓名：{{ item1.real_name }}
                    </div>
                    <div
                      style="margin-top: 9px"
                      @click="handleMakePhone(item1.real_telphone)"
                    >
                      联系电话：{{ item1.real_telphone }}
                    </div>
                    <!-- <div style="margin-top: 9px;">订单号：{{item1.order_sn}}</div> -->
                    <div style="margin-top: 9px">
                      <span>订单状态：</span>
                      <span style="color: #ff8c00">{{
                        item1.status_name
                      }}</span>
                    </div>
                    <!-- <div style="margin-top: 9px;">科目：{{item1.good_name}}</div> -->
                    <div
                      style="
                        margin-top: 9px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>
                        <span>成功邀请：</span>
                        <span style="color: #00a0e8"
                          >{{ item1.total_share_member }}人</span
                        >
                      </div>
                      <div>
                        <span>报名未支付:</span>
                        <span style="color: #00a0e8"
                          >{{ item1.total_needpay_order }}人</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <!-- 潜在、全部、成功 -->
            <div v-else style="padding-bottom: 9px">
              <div
                style="padding: 8px; box-sizing: border-box; position: relative"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0 6px;
                    border-bottom: 1px solid #e8e8e8;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <!-- <div style="width: 21px; height: 21px; border-radius: 50%; background: #00a0e8; margin-right: 7px;"></div> -->
                    <img
                      :src="item.avatar_url"
                      alt=""
                      style="
                        width: 21px;
                        height: 21px;
                        border-radius: 50%;
                        margin-right: 7px;
                      "
                    />

                    <div style="margin-right: 13px">
                      {{ item.nickname ? item.nickname : "" }}
                    </div>
                    <div>{{ item.telphone ? item.telphone : "" }}</div>
                  </div>

                  <div
                    @click="handleMakePhone(item.real_telphone)"
                    style="color: #00a0e8"
                  >
                    <img
                      src="~@/assets/bohao.png"
                      width="21"
                      height="21"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>

                <div
                  v-if="type !== 5"
                  style="
                    position: absolute;
                    top: 50px;
                    right: 10px;
                    color: #f68624;
                  "
                  @click="handleCreateHb(item)"
                >
                  生成海报
                </div>
                <div style="margin-top: 9px">
                  {{
                    type === 5
                      ? "报名时间"
                      : type === 4 || type === 7
                      ? "购买时间"
                      : ""
                  }}
                  ：{{ item.createtime }}
                </div>
                <div style="margin-top: 9px">
                  归属员工：{{
                    item.from_admin ? item.from_admin.realname : "未获取到"
                  }}
                </div>
                <div style="margin-top: 9px">
                  推荐人：{{
                    item.from_user && item.from_user.nickname
                      ? item.from_user.id + " " + item.from_user.nickname
                      : "---"
                  }}
                </div>
                <div style="margin-top: 9px">
                  学生姓名：{{ item.real_name }}
                </div>
                <div
                  style="margin-top: 9px"
                  @click="handleMakePhone(item.real_telphone)"
                >
                  联系电话：{{ item.real_telphone }}
                </div>
                <!-- <div style="margin-top: 9px">订单号：{{ item.order_sn }}</div> -->
                <div style="margin-top: 9px">
                  <span>订单状态：</span>
                  <span style="color: #ff8c00">{{ item.status_name }}</span>
                </div>
                <!-- <div style="margin-top: 9px">
                  科目：{{
                    item.good && item.good.good_name
                      ? item.good.good_name
                      : "---"
                  }}
                </div> -->
                <div
                  style="
                    margin-top: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <span>成功邀请：</span>
                    <span style="color: #00a0e8"
                      >{{ item.total_share_member }}人</span
                    >
                  </div>
                  <div>
                    <span>报名未支付:</span>
                    <span style="color: #00a0e8"
                      >{{ item.total_needpay_order }}人</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="text-align: center">
        <div
          @click="handleClickMore"
          style="
            font-size: 14px;
            color: #828282;
            padding: 5px 0;
            display: inline-block;
          "
        >
          加载更多
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3001;
          color: #fff;
          font-weight: 400;
          font-size: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          padding: 10px 15px;
        "
      >
        {{ hintContent }}
      </div>
    </div>

    <!-- 展示海报 -->
    <div v-if="isShowPoster">
      <div class="mask"></div>
      <div
        style="
          z-index: 3001;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 82%;
        "
      >
        <div
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <div
            style="
              color: #fff;
              font-size: 16px;
              background: #ff7418;
              padding: 5px 15px;
              border-radius: 7px;
            "
          >
            长按图片保存相册
          </div>
          <img
            @click="isShowPoster = false"
            style="width: 23px; height: 23px; margin-left: 50px"
            src="../assets/close.png"
            alt=""
          />
        </div>

        <div
          style="
            margin-top: 10px;
            background: #ffb400;
            border-radius: 15px;
            height: 80vh;
            position: relative;
          "
        >
          <img
            v-if="postersUrl"
            style="width: 100%; height: 100%; border: none"
            :src="postersUrl"
            alt=""
          />
          <div
            v-else
            style="
              color: #f7f2f3;
              font-size: 14px;
              text-align: center;
              position: absolute;
              width: 100%;
              top: 10%;
            "
          >
            海报背景-由设计师审核的时候设计上传
          </div>

          <!-- <div
            style="
              position: absolute;
              width: 100%;
              height: 91px;
              box-sizing: border-box;
              border-radius: 6px;
              background: #fff;
              left: 0;
              bottom: 8px;
              padding: 8px 15px 8px 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div>
              <div style="font-weight: 400; display: flex; align-items: center">
                <img
                  :src="curHbInfo.avatar_url"
                  alt=""
                  style="
                    margin-right: 6px;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                  "
                />
                <div>
                  <div style="color: #202020; font-size: 16px">
                    {{ curHbInfo.nickname }}
                  </div>
                  <div style="color: #686767; font-size: 12px">
                    邀请您参加本活动
                  </div>
                </div>
              </div>
              <div style="color: #ff2a00; font-size: 14px; margin-top: 6px">
                长按识别二维码立即秒杀
              </div>
            </div>

            <div
              class="qrcode"
              ref="qrCodeUrl"
              style="border-radius: 6px; width: 75px; height: 75px"
            ></div>
          </div> -->
        </div>

        <div class="refresh-wrap" @click="getPostersContent(curHbInfo)">
          <i class="el-icon-refresh-right"></i>
          <span>看不到二维码，点我刷新</span>
        </div>
      </div>
    </div>

    <el-dialog
      title="导出"
      :visible.sync="isShowExport"
      width="340px"
      @close="isShowExport = false"
      :close-on-click-modal="false"
    >
      <div
        style="
          padding-top: 28px;
          height: 100px;
          color: #409eff;
          text-align: center;
          font-size: 14px;
        "
      >
        <i class="el-icon-check" style="font-size: 50px"></i>
        <div>已成功</div>
      </div>
      <el-button
        style="width: 100%; margin-top: 15px"
        class="copy-export"
        :data-clipboard-text="exportUrl"
        @click="handleExportCopy"
        >复制链接到浏览器中打开</el-button
      >
      <span></span>
      <el-button
        type="primary"
        style="width: 100%; margin-top: 15px"
        @click="handleExport"
        >直接打开</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
// import QRCode from 'qrcodejs2'
import { Dialog } from 'vant'
import { randomString } from '@/utils/common.js'
export default {
  name: "CustomerData",
  components: {
  },
  data() {
    return {
      // 客户列表
      customerList: [],
      tabs: [
        {
          id: 4,
          name: '成功报名'
        },
        {
          id: 5,
          name: '潜在客户'
        },
        {
          id: 6,
          name: '全部组队'
        },
        {
          id: 7,
          name: '全部单买'
        },
      ],
      // 活动id
      id: '',
      // 类型  4: 成功报名  5: 潜在客户  6：全部组队  7: 全部单买
      type: -1,
      // 用户信息
      userInfo: {},
      // 复制的内容
      copyText: '',
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      page: 1,
      total: 0,

      isShowPoster: false,
      postersUrl: "",
      curHbInfo: {},

      exportUrl: '',
      isShowExport: false
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const { id, type } = this.$route.query;
    this.id = parseInt(id);
    this.type = parseInt(type);
    this.getCustomerData(this.type);
  },
  methods: {
    /** 处理点击导出按钮 */
    handleExport() {
      // Dialog
      window.location.href = "http://dev.ihycc.cn/api.php/down/outFIleByPayList?event_id=" + this.id + "&token=" + localStorage.getItem("token");
    },

    handleExportCopy() {
      this.exportUrl = "http://dev.ihycc.cn/api.php/down/outFIleByPayList?event_id=" + this.id + "&token=" + localStorage.getItem("token");
      const clipboard = new this.Clipboard(`.copy-export`);

      clipboard.on('success', () => {
        this.showMessage("复制成功,请到浏览器中打开");
        clipboard.destroy();
      });
    },

    handleTabs(item) {
      this.type = item.id
      this.customerList = []
      this.getCustomerData(item.id)
    },

    /** 复制组队链接 */
    handleCopyTeamLink(item, index) {
      this.copyText = "http://pp.ihycc.cn/event_" + randomString() + "?id=" + item.event_id + "&invite_id=" + this.userInfo.id;
      const clipboard = new this.Clipboard(`.copy-text${index}`);

      clipboard.on('success', () => {
        this.showMessage("复制成功");
        clipboard.destroy();
      });
    },

    /** 处理点击拨号 */
    handleMakePhone(phone) {
      Dialog.confirm({
        title: '提示',
        message: `确定拨打电话：${phone}吗？`,
      })
        .then(() => {
          window.location.href = 'tel:' + phone;
        })
        .catch(() => {
          // on cancel
        });
      // 
    },

    /** 获取客户信息 4: 成功报名  5: 潜在客户  6：全部组队  7: 全部单买 */
    async getCustomerData(type) {
      let tempArray = [];
      if (type === 4) {
        tempArray = await this.getSuccessfulRegistrationList(this.id);
      }
      else if (type === 5) {
        tempArray = await this.getPotentialCustomersList(this.id);
      }
      else if (type === 6) {
        tempArray = await this.getAllTeamList(this.id);
      }
      else if (type === 7) {
        tempArray = await this.getAllPayList(this.id);
      }
      console.log(tempArray, 'tempArraytempArray')
      for (const item of tempArray) {
        this.$set(this.customerList, this.customerList.length, item);
      }
    },

    /** 处理点击加载更多 */
    handleClickMore() {
      if (this.total <= this.page * 15) {
        this.showMessage("没有更多了");
        return;
      }
      this.page++;
      this.getCustomerData(this.type);
    },

    /** 获取全部买单列表 */
    getAllPayList(id) {
      return new Promise(resolve => {
        this.$axios.post("/teams/getEventTuanMmeber", {
          event_id: id,
          page: this.page,
        }).then(res => {
          this.total = res.total;
          resolve(res.data);
        }).catch(error => {
          this.showMessage(error);
        });
      });
    },

    /** 获取全部组队列表 */
    getAllTeamList(id) {
      return new Promise(resolve => {
        this.$axios.post("/teams/getEventTuan", {
          event_id: id,
          page: this.page,
        }).then(res => {
          this.total = res.total;
          resolve(res.data);
        }).catch(error => {
          this.showMessage(error);
        });
      });
    },

    /** 获取潜在客户列表 */
    getPotentialCustomersList(id) {
      return new Promise(resolve => {
        this.$axios.post("/teams/getEventPayOrders", {
          event_id: id,
          page: this.page,
        }).then(res => {
          this.total = res.total;
          resolve(res.data);
        });
      });
    },

    /** 获取成功报名列表 */
    getSuccessfulRegistrationList(id) {
      return new Promise(resolve => {
        this.$axios.post("/teams/getEventOrders", {
          event_id: id,
          page: this.page,
        }).then(res => {
          this.total = res.total;
          resolve(res.data);
        }).catch(error => {
          this.showMessage(error);
        });
      });
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },

    /** 返回上一页 */
    goBack() {
      this.$router.back();
    },

    // 生成海报
    handleCreateHb(item) {
      console.log(item, 'daaaa')
      this.curHbInfo = item
      this.getPostersContent(item);
      this.isShowPoster = true;
      // this.$nextTick(() => {
      //   this.creatQrCode(item);
      // })
    },

    /** 获取活动海报内容 */
    getPostersContent(item) {
      console.log(item, '2222')
      this.$axios.post("/index/getshareImage", {
        id: this.id,
        invite_id: item.member_id,
        user_id: item.member_id,
      }).then(res => {
        this.postersUrl = res.image_url;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 创建二维码 */
    // creatQrCode(item) {
    //   console.log(item)
    //   this.$refs.qrCodeUrl.textContent = ''
    //   this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
    //     text: "http://pp.ihycc.cn/event_biginfo?id=" + this.id + "&invite_id=" + item.member_id,
    //     width: 75,
    //     height: 75,
    //     colorDark: '#ffffff',
    //     colorLight: '#F05A11',
    //     correctLevel: QRCode.CorrectLevel.H
    //   })
    // },
  }
};
</script>

<style scoped lang="scss">
.CustomerData {
  background: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  font-weight: bold;

  .tabs-warp {
    height: 44px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #797a7d;
    font-size: 14px;
    .tab-item {
      position: relative;
      height: 100%;
      &.active {
        font-size: 16px;
        color: #1a1f21;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          display: block;
          transform: translateX(-50%);
          width: 50%;
          height: 4px;
          background: #4372fc;
        }
      }
    }
  }
}

::v-deep .el-dialog {
  border-radius: 5px;
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    padding-top: 10px;
  }
}

::v-deep .el-card__body {
  padding: 15px 7px !important;
}

._copy-style {
  background: #e8edfc;
  border-radius: 4px;
  border: 1px solid #e8edfc;
}

.refresh-wrap {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #4372fc;
  letter-spacing: 1px;
  i {
    font-size: 16px;
  }
  span {
    margin-left: 10px;
  }
}
</style>
